@import 'themes/breakpoints.scss';

.logo {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  @include md {
    margin-bottom: 0;
    justify-content: flex-start;
  }
  img {
    width: 188px;
    height: 16px; 

    @include md {
      width: 188px;
      height: 16px;    
    }    
  }


  &__auth {
    width: 100%;
    justify-content: center;
    padding: 40px 0 8px;
    @include md {
      padding: 64px 0 32px;
    }
  }

  &__main {

    @include md {
      padding: 6.5px 0 59px;
    }
  }

}