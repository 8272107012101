@import 'themes/colors.scss';
@import 'themes/breakpoints.scss';
@import 'themes/settings.scss';

.fz54-page {
  &__title {
    font-weight: 500;
    font-size: pxToRem(24px);
    line-height: pxToRem(30px);
    color: #000000;

    margin-bottom: 16px;
    @include md {

    }
  }
  &__container {
    &_papers {
      display: flex;
      flex-direction: column;
    }
    &_papers > * + * { 
      margin-top: 16px;
    } 

    @include md {
      &_papers {
        flex-direction: row;
      }
      &_papers > * + * { 
        margin-top: 0;
        margin-left: 16px;
      } 
    }
   
  }
  &__controls {
    margin-top: 24px;
    @include md {
      position: absolute;
      bottom: 24px;
      left: 24px;
    }
    &_full-width {
      right: 24px;
      display: flex;
      & a {
        flex: 1;
        text-align: center;
      }
    }
  } 
  &__block {
    &_top {
      margin-bottom: 32px;
    }
    @include md {
      flex: 1;
    }
  }
  &__block-title {

    font-weight: 500;
    font-size: 24px;
    line-height: 30px;  
    color: #000000;
    margin-bottom: 8px;
    @include md {
      margin-bottom: 16px;
    }
    &_inner {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 19px;
    }
  }

  &__block-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;

    &_same-height {
      @include md {
        margin-bottom: 85px;
      }      
    }

    &_cut {
      @include md {
        width: 65%;
      } 
    }

    & ul {
      list-style-image: url('../../../../assets/images/ul-check-mark.png');
      > * + * { 
        margin-top: 11px;
      }
      @include md {
        margin-top: 14px;
      } 
    }
  }

  &__block-chip {
    background-color: #DFF1FF;
    padding: 6px 14px;
    text-align: center;

    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    box-shadow: 0px 4px 25px rgba(92, 151, 255, 0.1);
    border-radius: 90px;

    margin-bottom: 16px;

    @include md {
      text-align: left;
      margin-bottom: 24px;
      display: inline-block;
    }
  }
  &__block-image {
    height: 38px;
    padding: 8px;
    margin-bottom: 24px;
    & img {
      height: 100%;
    }
    @include md {
      margin-bottom: 32px;
    }
  }
}

.fz54-modal {
  &__controls {
    &_full-width {
      display: flex;
      & a {
        flex: 1;
        text-align: center;
        align-items: center;
        & svg {
          display: none;
          @include md {
            display: inline;
          }
        }
      }
    }
  } 
  &__title {
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    color: #000000;
    margin-bottom: 8px;
  }
  &__subtitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 16px;
  }
  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    margin-bottom: 24px;
    @include md {
      margin-bottom: 32px;
    }
  }
}

.fz54-modal-steps {
  display: flex;
  margin-bottom: 32px;

  & > * + * { 
    margin-top: 16px;
    @include md {
      margin-left: 16px;
      margin-top: 0;
    }
  }  

  flex-direction: column;

  @include md {
    flex-direction: row;
  }

  &__item {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include md {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__name {
    padding: 4px 16px;
    background: #8B74D8;
    border-radius: 99px;
    display: inline-block;
    white-space: nowrap;

    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    margin-right: 16px;
    @include md {
      margin-bottom: 16px;
      margin-right: 0;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
  }
}

.fz54-modal-blocks {
  margin-bottom: 32px;
  display: flex;

  & > * + * { 
    margin-top: 16px;
    @include md {
      margin-left: 16px;
      margin-top: 0;
    }
  }

  flex-direction: column;

  @include md {
    flex-direction: row;
  }

  &__item {
    padding: 18px;
    border: 1px solid #D7D9DA;
    border-radius: 8px; 
    flex: 1;   
  }

  &__image {
    margin-bottom: 18px;
    & img {
      width: 100%;
    }
  }
  &__name {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #81899A;
    text-align: center;

    margin-bottom: 4px;
  }
  &__text {
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
    color: #81899A;
    text-align: center;

    & span {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #000000;

      & b {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
      }

      & s {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-decoration-line: line-through;
        color: #81899A;
      }
    }

  }
  &__text > * + * { 
    margin-top: 4px;
  }
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-center > * + * { 
  margin-left: 8px;
}

.modal {
  padding: 24px;
  height: 100%;
  overflow: auto;
  @include md {
    padding: 40px;
    width: 953px;    
  }
}