@import 'themes/colors.scss';
@import 'themes/breakpoints.scss';

.menu {
  
  display: flex!important;
  flex-direction: column;
  //gap: 32px;
  padding: 32px;
  
  @include md {
    display: none!important;
  }
  &__icon {
    height: 11px;
    & path {
      stroke: $violet_8b74d8; 
    }
  }

  &__link {
    text-decoration: none;
    display: flex;
    align-items: center;
  }
}

.menu > * + * { 
  margin-top: 32px
}

.fresh {
  & span {
    display: flex;
    &::after {
      width: 8px;
      height: 8px;  
      content: ' ';
      background-color: $red_eb5757;
      border-radius: 50%;
      position: relative;
      margin-left: 16px;
      margin-top: 1px;
      transform: translateY(50%)
    }
  }
}