@import 'settings.scss';

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
      @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
      @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
      @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
      @content;
  }
}
