.container {
 display: flex;
 width: 100%;
 flex-direction: column;
 justify-content: center;
 align-items: center; 
 flex: 1;
}

.over {
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #ffffff;
  opacity: 0.6;
  border-radius: 16px;
}