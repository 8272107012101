@import 'themes/colors.scss';
@import 'themes/breakpoints.scss';
@import 'themes/settings.scss';

.bank-tabs-container {
  margin: 22px 0 0 0;
  padding: 0;
  display: flex;
  position: relative;
  z-index: 1;
  &__li {
    list-style: none;
    padding: 10px 32px;
    cursor: pointer;
    &_active {
      border-radius: 16px 16px 0px 0px;
      background-color: #ffffff;
      cursor: default;
    }
  }
  &__bank-name {
    display: block;
    text-align: left;
    font-weight: 500;   

    font-size: pxToRem(28px);
    line-height: pxToRem(34px);

    @include md {
      font-size: pxToRem(18px);
      line-height: pxToRem(24px);      
    } 
  }
  &__status {
    text-align: left;
    display: block;
    font-style: normal;
    font-weight: 400;

    font-size: pxToRem(14px);
    line-height: pxToRem(18px);
    
    @include md {
      font-size: pxToRem(11px);
      line-height: pxToRem(13px);      
    }
  }

  &__disabled {
    color: #AEB8BF!important;
    cursor: default;
  }
}

.bank-tabs-container > * + * { 
  margin-left: 20px;
}