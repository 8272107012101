@import 'themes/colors.scss';
@import 'themes/breakpoints.scss';

.menu {
  display: none!important;
  @include md {
    display: flex!important;
  }
  &__icon {
    margin-right: 16px;
  }
  &__new-message-indicator {
    width: 8px;
    height: 8px;
    background: linear-gradient(0deg, #EB5757, #EB5757), #FF002E;
    text-align: center;
    margin-left: 16px;
  }
}

.fresh {
  & span {
    display: flex;
    &::after {
      width: 8px;
      height: 8px;  
      content: ' ';
      background-color: $red_eb5757;
      border-radius: 50%;
      position: relative;
      margin-left: 16px;
      margin-top: 1px;
      transform: translateY(50%)
    }
  }
}