@import 'themes/colors.scss';
@import 'themes/breakpoints.scss';

.mobile-menu {

  margin-left: 10px;
  display: flex;
  align-items: center;

  @include md {
    display: none;
  }

  & .inner-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    //gap: 8px;

    position: absolute;
    left: 0px;
    top: 53px;
    background: $monochrome_ffffff;
    box-shadow: 0px 4px 25px rgba(92, 151, 255, 0.1);
    border-radius: 0px 0px 16px 16px;
    width: 100%;
    z-index: 3;
    &__icon {
      margin-right: 16px;
    }  
    &__new-message-indicator {
      width: 8px;
      height: 8px;
      background: linear-gradient(0deg, #EB5757, #EB5757), #FF002E;
      text-align: center;
      margin-left: 16px;
    }  
  }
  & .inner-menu  > * + * { 
    margin-top: 8px
  }  
}

.fresh {
  & span span {
    display: flex;
    &::after {
      width: 8px;
      height: 8px;  
      content: ' ';
      background-color: $red_eb5757;
      border-radius: 50%;
      position: relative;
      margin-left: 16px;
      margin-top: 1px;
      transform: translateY(50%)
    }
  }
}