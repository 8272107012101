@import "themes/colors.scss";
@import "themes/breakpoints.scss";

.field {
  box-sizing: border-box;
  display: block;
  position: relative;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  visibility: hidden !important;
}

.filedrop {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //cursor: pointer;
  overflow: hidden;

  background-color: transparent;//$background-default;

  width: 100%;
  min-height: 90px;

  @include md {
    min-height: 80px;
  }


  
  border: 1px dashed;
  border-color: #D7D9DA;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;

  &--dragging {
    background-color: $monochrome_f4f7fc;
    border-color: #D7D9DA;//$color-secondary;
  }

  &__target {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    padding: 8px;

    @include md {
      padding: 8px 16px;
    }
  }
}
