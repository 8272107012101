@import 'themes/colors.scss';
@import 'themes/settings.scss';
@import 'themes/breakpoints.scss';

.survey-modification {
  @include md {  
    margin-left: 32px;
    align-items: center;
  }
  &__locker {
    margin-right: 16px;
    @include md {
      margin-right: 0;
    }
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include md {
    flex-direction: row;
  }
  justify-content: space-between;
  &__title {
    font-size: pxToRem(28px);
    line-height: pxToRem(34px);
    color: $monochrome_ffffff;        
    &_passive {
      font-size: pxToRem(20px);
      line-height: pxToRem(24px);
      color: $blue_adcdf9;
    }
  }
  &__hint {
    font-size: pxToRem(14px);
    line-height: pxToRem(18px);
    color: $monochrome_ffffff;  

    margin-top: 2px;
  }
}
.button {
  margin: 32px 0 20px!important;
  @include md {
    margin: 0!important;
  }
}
.container {
  flex-direction: column;
  align-items: flex-start;
  @include md {
    flex-direction: row;
    align-items: unset;
  }
}
.error{
  background: linear-gradient(89.92deg, #EF6033 0.05%, #DF9BFF 99.93%), linear-gradient(90deg, #8F73E9 0%, #4BAFF3 100%);
}

.modal {
  text-align: center;
  padding: 40px 0px;
  width: 100%;
  @include md {
    width: auto;
    padding: 40px 100px;
  }
  &__title {
    font-size: pxToRem(20px);
    line-height: pxToRem(24px);
    color: $monochrome_000000;        
  }
  &__text {
    font-size: pxToRem(14px);
    line-height: pxToRem(18px);
    color: #81899A;

    margin-top: 16px;

    & a {
      color: #8B74D8;
    }
  }
}