@font-face {
	font-family: 'Inter';
  src: url('./fonts/Inter-Regular.eot');
  src: local('Inter Regular'), local('Inter-Regular'),
      url('./fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Inter-Regular.woff2') format('woff2'),
      url('./fonts/Inter-Regular.woff') format('woff'),
      url('./fonts/Inter-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'Inter';
  src: url('./fonts/Inter-Medium.eot');
  src: local('Inter Medium'), local('Inter-Medium'),
      url('./fonts/Inter-Medium.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Inter-Medium.woff2') format('woff2'),
      url('./fonts/Inter-Medium.woff') format('woff'),
      url('./fonts/Inter-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'Inter';
  src: url('./fonts/Inter-Bold.eot');
  src: local('Inter Bold'), local('Inter-Bold'),
      url('./fonts/Inter-Bold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Inter-Bold.woff2') format('woff2'),
      url('./fonts/Inter-Bold.woff') format('woff'),
      url('./fonts/Inter-Bold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
  font-display: swap;
}