.MuiSlider-thumb {
  width: 25px!important;
  height: 15px!important;
  border-radius: 10px!important;  
}

.MuiSlider-markLabel {
  top: 12px!important;
}

.MuiSlider-markLabel[data-index="3"] {
  left: unset!important;
  right: 0!important;
  transform: translateX(0%)!important;
}