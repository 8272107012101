@import 'themes/colors.scss';
@import 'themes/settings.scss';
@import 'themes/breakpoints.scss';

.steps-list {
  margin-top: 16px;
  &__contents {
    display: flex;
    justify-content: space-between;
    & > div {
      width: 20%;
      &:first-child, &:last-child {
        width: 10%;
      }
    }
   }
   &__hint {
    margin-top: 24px;
    font-size: pxToRem(11px);
    line-height: pxToRem(13px);
    color: $monochrome_81899a;
  }
  &__flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  &__flex-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__flex-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__accordion-container {
    margin-top: 16px;
  }
}

.modal {
  text-align: center;
  padding: 40px 0px;
  width: 100%;
  @include md {
    width: auto;
    padding: 40px 100px;
  }
  &__title {
    font-size: pxToRem(20px);
    line-height: pxToRem(24px);
    color: $monochrome_000000;        
  }
  &__text {
    font-size: pxToRem(14px);
    line-height: pxToRem(18px);
    color: #81899A;

    margin: 16px 0;

    & a {
      color: #8B74D8;
    }
  }
}

.accordion {
  &__title {
    display: flex;
    & :nth-child(2) {
      display: none;
      @include md {
        display: block;
      }
    }

    
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    margin-right: 20px;
  }
}