@import 'themes/colors.scss';
@import 'themes/settings.scss';

.step-label {
  background-color: $violet_8b74d8;
  padding: 6px 14px;
  border-radius: 90px;

  font-size: pxToRem(14px);
  line-height: pxToRem(17px);

  color: $monochrome_ffffff;

  display: inline-block;


  font-weight: 500;

}

.bottom-margin {
  margin-bottom: 16px;
}