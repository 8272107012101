@import 'themes/colors.scss';
@import 'themes/settings.scss';
@import 'themes/breakpoints.scss';

.survey-connection-agreement {
  @include md {  
    margin-left: 32px;
    align-items: center;
  }
  width: 100%;
  display: flex;
  &__locker {
    margin-right: 16px;
    @include md {
      margin-right: 0;
    }
  }
  flex-direction: column;
  align-items: flex-start;
  @include md {
    flex-direction: row;
  }

  justify-content: space-between;

  &__title {
    font-size: pxToRem(28px);
    line-height: pxToRem(34px);
    color: $monochrome_ffffff;        
    &_passive {
      font-size: pxToRem(20px);
      line-height: pxToRem(24px);
      color: $blue_adcdf9;
    }
  }
  &__hint {
    font-size: pxToRem(14px);
    line-height: pxToRem(18px);
    color: $monochrome_ffffff;  

    margin-top: 2px;
  }
  &__text-block {
    @include md {
      margin-right: 40px;
    }
  }
}
.container {
  flex-direction: column;
  align-items: flex-start;
  @include md {
    flex-direction: row;
    align-items: unset;
  }
}
.button {
  margin: 32px 0 20px!important;
  @include md {
    margin: 0!important;
    flex: 1 0 auto;
  }
}
