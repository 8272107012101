@import 'themes/colors.scss';
@import 'themes/settings.scss';
@import 'themes/breakpoints.scss';

.steps-list {
  margin-top: 16px;
  &__hint {
    margin: 32px;
    font-size: pxToRem(11px);
    line-height: pxToRem(13px);
    color: $monochrome_81899a;
    @include md {
      margin: 32px 0  0;
    }
  }  
  &__contents {
    display: flex;
    justify-content: space-between;
    & > div {
      width: 20%;
      &:first-child, &:last-child {
        width: 10%;
      }
    }
   }

  &__flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  &__flex-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    span, a {
      text-align: right !important;
    }    
  }
  &__flex-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span, a {
      text-align: left !important;
    }
  }
  &__mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;    
    @include md {
      display: none;
    }
  }
  &__desktop {
    display: none;
    @include md {
      display: block;

    }
  }
}
