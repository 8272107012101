// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 1279px;//768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// base font size
$fs-base: 16px;

@function strip-unit($v) {
  @return calc($v / ($v * 0 + 1));
}

// convert px to rem
@function pxToRem($v) {
  @return #{strip-unit(calc($v / $fs-base))} + rem;
}

// vertical gap
$auth_vertical_gap: 20px;
$main_vertical_top_gap: 29.5px; 
$main_vertical_bottom_gap: 55px;

// desktop footer height
$footer_height_desktop: 82px;

// mobile footer height
$footer_height_mobile: 144px;

$private_part_width: 1500px;

:export {
  screenXLMin: $screen-md-min;
}