@import 'themes/settings.scss';
@import 'themes/colors.scss';
@import 'themes/breakpoints.scss';

.footer {
  position: absolute;  
  display: flex;
  bottom: 0;
  width: 100%;
  border-top: 1px solid $violet_8b74d8;
  


  &__inner {  
    display: flex;
    padding: 24px;
    flex-direction: column;   
    margin: 0 auto; 
  }


  @include md() {

    &__inner {
      padding: 24px;    
      flex-direction: row;      
      width: $private_part_width;
    }    
  }

  background-color: $monochrome_ffffff;
  align-items: center;
  justify-content: space-between;
  font-size: pxToRem(14px);
  line-height: 18px;
  color: $monochrome_000000;
  &__part-one {
    display: flex;
    
    width: 100%;
    flex-direction: column;
    border-bottom: 1px solid $monochrome_d7d9da;
    padding-bottom: 16px;
    margin-bottom: 16px;

    @include md() {
      width: auto; 
      flex-direction: row;
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  &__part-two {
    display: flex;
    width: 100%;
    @include md() {
      width: auto; 
      margin-left: auto;
    }
    div {
      width: 50%;
      @include md() {
        width: auto; 
      }

      &:first-child {
        border-right: 1px solid $monochrome_d7d9da;
        @include md() {
          border-right: 0;
        }
      }  
    }
  }
  &__item {
    justify-content: center;
    text-align: center;
    &_mb {
      margin-bottom: 8px;
    }
    @include md() { 
      justify-content: flex-start;
      text-align: left;
      &_mb {
        margin-bottom: 0;
      }
      &:first-child {
        margin-right: 24px;
      }      
    }


    a {
      color: $monochrome_000000;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}