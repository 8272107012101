@import 'themes/colors.scss';
@import 'themes/breakpoints.scss';
@import 'themes/settings.scss';

.white-paper {
  background: $monochrome_ffffff;
  border-radius: 8px;
  padding: 24px;
  position: relative;
  
  border-top: 2px solid transparent;
  @include md {
    border-top: 4px solid transparent;
  }
  position: relative;
  flex: 1;

  &__topper-color {
    
    &_violet {
      border-top-color: #8B74D8;
    }
    &_orange {
      border-top-color: #FF8F00;
    }
    &_red {
      border-top-color: #ED1B2F;
    }
  }
}