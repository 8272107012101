@import 'themes/colors.scss';
@import 'themes/settings.scss';

.feedback-messages {
  margin-top: 16px;
  position: relative;
  &:first-child {
    margin-top: 33px;
  }
  &__container {
    display: flex;
    //gap: 16px;

    &-new {
      background: #F3FFF6!important;
    }
  }
  &__container > * + * { 
    margin-left: 16px;
  }  
  &__avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    /* background-color: $monochrome_000000; */
    flex: none;
  }
  &__indicator {
    position: absolute;
    width: 8px;
    height: 8px;
    left: -8px;
    top: 8px;
    background: #01BE6F;
    border: 1px solid #0FAD81;
    border-radius: 50%;
  }
  &__date {
    font-size: pxToRem(11px);
    line-height: pxToRem(13px);
    color: $monochrome_81899a;

    margin-top: 4px;
  }
  &__message {
    font-size: pxToRem(14px);
    line-height: pxToRem(18px);
    color: $monochrome_000000;
    font-weight: 400;

    &_manager-name {
      font-weight: 600;
      margin-bottom: 10px;
    }

    &_link {
      margin-top: 16px;

      > a {
        text-decoration: underline;
        color: #8B74D8;
        cursor: pointer;
      }
    }
  }
}