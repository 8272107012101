@import 'themes/colors.scss';
@import 'themes/settings.scss';
@import 'themes/breakpoints.scss';

.feedback-page {
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__empty-container {
    padding: 24px;
    @include md {
      padding: 72px;
    }  
  }
  &__title {
    font-weight: 500;
    font-size: pxToRem(28px);
    line-height: pxToRem(34px);
    text-align: center;
    color: $monochrome_000000;

    margin-top: 16px;
  }
  &__hint {
    font-size: pxToRem(14px);
    line-height: pxToRem(18px);
    text-align: center;
    color: $monochrome_000000;

    margin-top: 8px;
  }

  &__manager-name {
    font-size: pxToRem(14px);
    line-height: pxToRem(18px);
    color: #81899A;
    display: none;

    @include lg {
      display: flex;
      align-items: center;
    }

    &_mobile {
      font-size: pxToRem(14px);
      line-height: pxToRem(18px);
      color: #81899A;
      display: block;
      margin: 26px 0 31px;

      @include lg {
        display: none;
      }
    }
  }
}