@import 'themes/colors.scss';
@import 'themes/settings.scss';
@import 'themes/breakpoints.scss';

.contacts {

  margin-top: 34px;

  display: flex;

  flex-direction: column;

  @include md {
    flex-direction: row;
    justify-content: space-between;    
  }

  &__content {
    display: flex;

    flex-direction: column;

    @include md {
      flex-direction: row;
      justify-content: space-between;    
    }
  }

  &__title {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;

    margin-bottom: 17px;
  }

  &__caption {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #81899A;

    margin-bottom: 4px;
  }

  &__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    padding-right: 16px;
    word-break: break-word;
    & a {
      color: #000000;
      text-decoration: none;
    }
  }
  &__content > * + * { 
    margin-top: 8px;
    @include md {
      margin-top: 0;
      margin-left: 32px; 
    }
         
  }
}

.contacts > * + * { 
  margin-top: 32px;
  @include md {
    margin-top: 0;
  }
}