@import 'themes/colors.scss';
@import 'themes/settings.scss';
@import 'themes/breakpoints.scss';

.order-number {
  background-color: $monochrome_ffffff;

  padding: 6px 16px;

  font-size: pxToRem(14px);
  line-height: pxToRem(17px);

  color: $monochrome_000000;

  display: flex;
  align-items: center;

  font-weight: 600;

  @include md {
    padding: 6px 14px;
    box-shadow: 0px 4px 25px rgba(92, 151, 255, 0.1);    
    border-radius: 90px;
  }

  &__icon {
    margin-right: 8px;
  }

  &__span {
    font-weight: 400;
    padding-right: 3px;
  }
}