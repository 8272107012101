@import 'themes/colors.scss';
@import 'themes/breakpoints.scss';
@import 'themes/settings.scss';

.main {
  padding: $main_vertical_top_gap 16px $main_vertical_bottom_gap;
  @include md {
    padding: $main_vertical_top_gap 24px $main_vertical_bottom_gap;
  }
  display: flex;
  align-items: flex-start;
  min-height: calc(100vh - $footer_height_mobile - $main_vertical_top_gap - $main_vertical_bottom_gap);
  width: 100%;
  flex-direction: column;

  @include md {
    padding: $main_vertical_top_gap 24px $main_vertical_bottom_gap;
    margin: 0 auto;
    min-height: calc(100vh - $footer_height_desktop - $main_vertical_top_gap - $main_vertical_bottom_gap);
    flex-direction: row;
    width: $private_part_width;
  }

  &__auth-block {
    width: 100%;
    border-radius: 16px;
    background-color: $monochrome_ffffff;
    padding: 0 24px 40px;
    @include md {
      padding: 0 64px 64px;
    }
    a {
      color: $violet_8b74d8;
      font-size: pxToRem(14px);
      line-height: pxToRem(18px);
    }
  }

  &__left-block {
    position: relative;
    width: 100%;
    @include md {
      margin-right: 65px;
      width: unset;
    }
  }
  &__right-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: inherit;
  }
}

.auth-form {
  margin-bottom: 16px;
}
