@import 'themes/breakpoints.scss';

.profile-menu {
  position: relative;
  display: none;
  
  @include md {
    display: flex;
  }

  &__arrowdown {
    &_reverse {
      transform: rotate(180deg);
    }
  }
  &__name {
    margin: 0 7px;
  }
  &__content {
    display: flex;
    cursor: pointer;
  }
  &__tooltip {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
}