@import 'themes/colors.scss';
@import 'themes/breakpoints.scss';
@import 'themes/settings.scss';

.step-description {
  margin-top: 16px;
  @include md {
    margin-top: 0;
    width: 70%;
  }
  &__caption {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    @include md {
      
    }
  }
  &__description {
    margin-top: 16px;

    font-size: 14px;
    font-weight: 400;
    line-height: 18px; 

    @include md {
      
    }
  }
}
