@import 'themes/colors.scss';
@import 'themes/breakpoints.scss';
@import 'themes/settings.scss';

.main {
  padding: $auth_vertical_gap 24px $auth_vertical_gap * 2;
  display: flex;
  align-items: center;
  min-height: calc(100vh - $footer_height_mobile - $auth_vertical_gap * 2);
  width: 100%;
  
  @include md {
    width: 471px;
    padding: $auth_vertical_gap 0;
    margin: 0 auto;
    min-height: calc(100vh - $footer_height_desktop - $auth_vertical_gap * 2);
  }

  &__auth-block {
    width: 100%;
    border-radius: 16px;
    background-color: $monochrome_ffffff;
    padding: 0 24px 40px;
    box-shadow: $white_block_shadow;
    @include md {
      padding: 0 64px 64px;
    }
    a {
      color: $violet_8b74d8;
      font-size: pxToRem(14px);
      line-height: pxToRem(18px);
    }
  }

  &__main-block {
    position: relative;
  }
}

.auth-form {
  margin-bottom: 16px;
}
