@import 'themes/colors.scss';
@import 'themes/settings.scss';
@import 'themes/breakpoints.scss';

.welcome-block {
  position: relative;
  &__title {
    font-weight: 500;
    font-size: pxToRem(28px);
    line-height: pxToRem(34px);
    
    color: $monochrome_000000;
  }
  &__hint {
    font-size: pxToRem(14px);
    line-height: pxToRem(18px);
    color: $monochrome_000000;

    margin-top: 8px;
  }
  &__list {
    margin-top: 16px;
    display: flex;
    //gap: 16px;
    flex-direction: column;
    @include md {
      flex-direction: row;
      //gap: 32px;
    }
    
  }

  &__list > * + * { 
    margin-top: 16px;
    @include md {
      margin-top: 0;
      margin-left: 32px;
    }
  }

  &__list-item {
    font-size: pxToRem(14px);
    line-height: pxToRem(18px);
    color: $monochrome_81899a;
    display: flex;
    align-items: center;
    & svg {
      margin-right: 8px;
    }
    &_active {
      color: $violet_8b74d8;
      & circle {
        fill: $violet_8b74d8;
      }
      & a {
        text-decoration: none;
        color: $violet_8b74d8;
      }
    }
  }
  
}