@import 'themes/colors.scss';
@import 'themes/settings.scss';
@import 'themes/breakpoints.scss';

.step-number-wrapper {
  position: relative;
  z-index: 2;
  height: 46px;
  width: 36px;
  
  align-items: center;
  background-color: $monochrome_ffffff;
  position: relative;
  margin-top: 16px;
  display: none;
  @include md {
    display: flex;
  }
}
.mobile-hidden {
  display: none;
  @include md {
    display: flex;
  }
}
.step-number {
  border: 1px solid $blue_adcdf9;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  font-weight: 500;
  font-size: pxToRem(14px);
  line-height: pxToRem(18px);
  color: $blue_adcdf9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  background-color: $monochrome_ffffff;
  &_active {
    border: $violet_8b74d8;
    background-color: $violet_8b74d8;
    color: $monochrome_ffffff;
  }
  &_declined, &_rejected {
    background: #EF6135;
    border: 1px solid #F06134;
    color: $monochrome_ffffff;
  } 
}