@import 'themes/colors.scss';
@import 'themes/settings.scss';
@import 'themes/breakpoints.scss';

.development-page {
  &__title {
    font-weight: 500;
    font-size: pxToRem(28px);
    line-height: pxToRem(34px);
    
    color: $monochrome_000000;
    @include md {
      text-align: center;
    }
  }
  &__hint {
    font-size: pxToRem(14px);
    line-height: (18px);
    text-align: center;
    color: $monochrome_000000;

    margin-top: 32px;
    & a {
      color: $violet_8b74d8;
    }    
  }
  &__text {
    font-size: pxToRem(14px);
    line-height: (18px);
    color: $monochrome_000000;

    & a {
      color: $violet_8b74d8;
    }    
  }
  &__part {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__button-container {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
  &__button {
    color: #ffffff!important;
    border-radius: 90px;
    background-color: #8B74D8;
    text-decoration: none;
    text-transform: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 14px 24px;

    &:hover {
      background-color: #625FBF;
    }
  }
  &__accordion-container {
    margin-bottom: 16px;
  }
  &__mobile-version-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin: 16px 0;
  }
}

.faq-page {
  &__title {
    font-weight: 500;
    font-size: pxToRem(20px);
    line-height: pxToRem(26px);
    display: flex;
    align-items: center;
    color: $monochrome_000000;
    & span {
      margin: 0 8px 0 16px;
      flex: 1 0;
    }
    @include md {
      font-size: pxToRem(18px);
      line-height: pxToRem(24px);
    }
  }
  &__hint {
    font-size: pxToRem(14px);
    line-height: (18px);
    text-align: center;
    color: $monochrome_000000;

    
    & a {
      color: $violet_8b74d8;
    }    
  }
  &__hint-container {
    margin-bottom: 12px;
  }
  &__part {
    margin-bottom: 16px;
    padding: 12px 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.with-question {
  display: flex;
  align-items: center;
}

.question-appearance {
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
}

.tabs {
  :first-child {
    margin-top: 0;
  }
}
.tabs__first {
  border-top-left-radius: 0!important;
}