@import 'themes/breakpoints.scss';
@import 'themes/colors.scss';
@import 'themes/settings.scss';

.footnote {
  font-size: pxToRem(14px);
  line-height: pxToRem(18px);
  color: $monochrome_000000;

  a {
    color: $violet_8b74d8;
  }
}