@import 'themes/colors.scss';
@import 'themes/settings.scss';
@import 'themes/breakpoints.scss';

.link {
  margin-top: 8px;
  display: block;
  color: $violet_8b74d8;
  font-size: pxToRem(14px);
  line-height: pxToRem(18px);
}

.modal-link {
  color: $violet_8b74d8;
  cursor: pointer;
}

.help {
  margin-left: 8px;
  margin-top: 4px;
  color: $monochrome_000000;
  font-size: pxToRem(11px);
  line-height: pxToRem(13px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__question-icon {
    cursor: pointer;
    width: 16px;
    &_container {
      display: flex;
      align-items: center;
    }
  }
}

.passive-help {
  font-size: pxToRem(11px);
  line-height: pxToRem(13px);
  margin-top: 2px;
  color: $monochrome_81899a;
}

.upload {
  &__label {
    text-align: center;
    font-weight: 400;
    font-size: pxToRem(14px);
    line-height: pxToRem(18px);

    color: #AEB8BF;
    &_blue {
      color: #8B74D8;
      cursor: pointer;
    }
    &_black, &_dragText {
      color: #000000;
    }

    &_container {
      display: inherit;
    }
  }  
  &__selection {
    /* margin-top: 16px; */
    width: 100%;
    &:empty {
      margin: 0;
    }
  }
}

.file {
  color: #000000;
  &__name {
    text-decoration: underline;
    font-size: pxToRem(14px);
    line-height: pxToRem(18px);
    white-space: break-spaces;
  }

  &__input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0 !important;
      width: 0 !important;
      height: 0 !important;
      visibility: hidden !important;
  }
  &__layout {
    padding: 14px 18px;
    display: flex;
    border: 1px solid #D7D9DA;
    background-color: #F8F8F8;
    border-radius: 6px;
    align-items: center;    
    //gap: 16px;
    flex-wrap: wrap;
    &_order {
      overflow-wrap: anywhere;
      flex-wrap: unset;
      flex-direction: column;
      @include md {
        flex-direction: row;
      }
    }
  }

  &__error {
    color: #d32f2f;
    //margin-top: 4px;
    //margin-left: 12px;
    font-family: Inter;
    font-weight: 400;
    font-size: pxToRem(14px);
    line-height: pxToRem(18px);
    border-color: #d32f2f;

    .file__name {
      color: #000000;
    }

    .file__remove path {
      stroke: #d32f2f;
    }

    

    @include md {
      flex-direction: row; 
    }

    &_warning {
      @include md {
        margin-left: auto;
      }
    }

    &_order {
      background: #FFEAEA;
      .file__name {
        text-decoration: none;
      }
    }
  }
}

.footnote {
  margin-top: 8px;
  color: $monochrome_81899a;
  font-size: 11px;
  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}

.download-section {
  margin-left: auto;
  display: flex;
  //gap: 10px;
  cursor: pointer;
  align-items: center;
  font-size: pxToRem(14px);

  & span {
    text-decoration: underline;
  }
}

.download-section > * + * { 
  margin-left: 10px;
}

.shrink {
  flex-shrink: 0;
}


.filedrop {
  padding: 16px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //cursor: pointer;
  overflow: hidden;

  background-color: transparent;//$background-default;

  width: 100%;
  min-height: 90px;

  @include md {
    min-height: 80px;
  }


  
  border: 1px dashed;
  border-color: #D7D9DA;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;

  &--dragging {
    background-color: $monochrome_f4f7fc;
    border-color: #D7D9DA;//$color-secondary;
  }

  &__target {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    padding: 8px;

    @include md {
      padding: 8px 16px;
    }
  }

  &__passport_scan {
    display: flex;
    justify-content: center;
  }

  &__error {
    border-color: #d32f2f;
  }
}

.MuiSlider-root {
  padding: 0!important;
  position: absolute!important;
  background-color: transparent!important;
  color: #8B74D8!important;
  top: 70px;
  left: 0;
  @include md {
    top: 70px;
  }
}

.MuiSlider-thumb {
    width: 25px;
    height: 15px;
    border-radius: 10px;  
}


.modal {
  text-align: center;
  padding: 16px 0px;
  width: 100%;
  max-width: 460px;
  @include md {
    width: auto;
    padding: 0;
  }
  &__title {
    font-size: pxToRem(20px);
    line-height: pxToRem(24px);
    color: $monochrome_000000;        
  }
  &__text {
    font-size: pxToRem(12px);
    line-height: pxToRem(18px);  
    color: #8B74D8;
    text-align: left;
    margin: 27px 0 32px;
    
    & span {
      color: #000000;
    }

    list-style-image: url('/assets/images/list-bullet.png');
    list-style-position: inside;

    & ul {
      padding: 0;
    }

    & li {
      line-height: 2.5;
    }
  }
}

.image-info {
  display: flex;
  justify-content: space-around;
  &__row {
    display: flex;
    flex-direction: column;
    align-items: center;
    & div {
      text-align: center;
      &:last-child {
        margin-top: 5px;
      }      
    }
  }
}