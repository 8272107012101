@import 'themes/colors.scss';
@import 'themes/settings.scss';

.step-number-wrapper {
  position: relative;
  z-index: 2;
  height: 36px;
  width: 46px;
  background-color: $monochrome_ffffff;
}

.step-number-container {
  width: 100%;
}
.step-number-link {
  text-decoration: none!important;
  display: block;
}
.step-number {
  border: 1px solid $blue_adcdf9;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  font-weight: 500;
  font-size: pxToRem(14px);
  line-height: pxToRem(18px);
  color: $blue_adcdf9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  background-color: $monochrome_ffffff;
  
 
  &_finished {
    border: $blue_4baff3;
    background-color: $blue_4baff3;
  }
  &_active {
    border: $violet_8b74d8;
    background-color: $violet_8b74d8;
    color: $monochrome_ffffff;
  }   
  &__name {
    margin-top: 8px;
    font-size: pxToRem(14px);
    line-height: pxToRem(18px);
    color: $monochrome_000000;
    text-decoration: underline;
    text-align: center;

    &_finished {
      color: $blue_4baff3;
      text-decoration: none;
    }
    &_active {
      color: $violet_8b74d8;
      text-decoration: none;
    }    
    &_disabled {
      color: $blue_adcdf9;
      text-decoration: none;
    }
  }
}