@import 'themes/colors.scss';
@import 'themes/settings.scss';
@import 'themes/breakpoints.scss';

.my-payments-label {
  background-color: $violet_8b74d8;
  padding: 6px 14px;
  border-radius: 90px;

  font-size: pxToRem(14px);
  line-height: pxToRem(17px);

  color: $monochrome_ffffff;

  display: flex;

  text-decoration: none;

  font-weight: 500;

  align-items: center;

  cursor: pointer;

  & span {
    padding-right: 8px;
  }

  margin-right: 16px;
  @include md {
    margin-right: 30px;   
  }


}