$gradient: linear-gradient(90deg, #C5B4FA 0%, #AEDFFF 100%);
$gradient_body: linear-gradient(0deg, rgba(197, 180, 250, 0.4) 0%, rgba(174, 223, 255, 0.4) 100%);
$monochrome_ffffff: #FFFFFF;
$monochrome_f4f7fc: #F4F7FC;
$monochrome_f8f8f8: #F8F8F8;
$monochrome_d7d9da: #D7D9DA;
$monochrome_aeb8bf: #AEB8BF;
$monochrome_81899a: #81899A;
$monochrome_000000: #000000;

$blue_adcdf9: #ADCDF9;
$blue_4baff3: #4BAFF3;
$blue_319ce5: #319CE5;
$blue_2d56c6: #2D56C6;
$blue_4baff3: #4BAFF3;
$blue_gradient: linear-gradient(90deg, #8F73E9 0%, #4BAFF3 100%);

$red_gradient: linear-gradient(0deg, #EB5757, #EB5757), #FF002E;
$red_e13a3a: #E13A3A;
$red_eb5757: #EB5757;

$green_f3fff6: #F3FFF6;
$green_01be6f: #01BE6F;
$green_0fad81: #0FAD81;

$violet_bfb8d5: #BFB8D5;
$violet_84abff: #84ABFF;
$violet_8b74d8: #8B74D8;
$violet_625fbf: #625FBF;

$white_block_shadow: 0px 4px 25px rgba(92, 151, 255, 0.1);
