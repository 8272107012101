@import 'themes/colors.scss';
@import 'themes/breakpoints.scss';
@import 'themes/settings.scss';

.contents-block {
  margin-top: 22px;
  &__margin-top {
    margin-top: 16px;
    display: flex;
    align-items: center;
    //gap: 16px;
    width: 100%;
  }
  &__margin-top > * + * { 
    @include md {
      margin-left: 16px;      
    }
  }
  &__margin-null {
    margin-top: 16px;
    display: flex;
    align-items: center;
    //gap: 16px;
    width: 100%;
  }
  &__margin-null > * + * { 
    margin-left: 16px;
  }

  &__accordion-container {
    margin-top: 16px;
  }
}

.tabs {
  margin-top: 0;
}

.tabs__first {
  border-top-left-radius: 0!important;
}