@import 'themes/colors.scss';
@import 'themes/settings.scss';
@import 'themes/breakpoints.scss';

.survey-steps {
  @include md {
    margin-left: 32px;
  }
  &__margin-bottom {
    margin-bottom: 20px;
    @include md {
      margin-bottom: 0;
    }
  }
  &__locker {
    margin-right: 16px;
    @include md {
      margin-right: 0;
    }
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__top {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include md {
      flex-direction: row;
    }
  }
  &__title {
    font-size: pxToRem(28px);
    line-height: pxToRem(34px);
    color: $monochrome_ffffff;

    margin: 32px 0 2px;
    @include md {
      margin: 0;
    }
    &_passive {
      font-size: pxToRem(20px);
      line-height: pxToRem(24px);
      color: $blue_adcdf9;
    }     
  }
  &__hint {
    font-size: pxToRem(14px);
    line-height: pxToRem(18px);
    color: $monochrome_ffffff;  

    margin-top: 2px;
  }
  &__list {
    display: flex;
    //gap: 2px;
  }
  &__list > * + * { 
    margin-left: 2px
  }
  &__list-item {

    background-color: $monochrome_ffffff; 
    box-shadow: $white_block_shadow;
    border-radius: 16px;
    height: 8px;
    width: 100%;
    opacity: 0.3;
    &a{
      cursor: pointer;
    }
    position: relative;

    &::before {
      content: '';
      position: absolute;
      display: block;
      top: -15px;
      left: 0;
      right: 0;
      bottom: -15px;
    }
    
    &_active {
      opacity: 1;
    }

  }
}
.button {
  margin: 32px 0 20px!important;
  @include md {
    margin: 0!important;
  }
}
.container {
  flex-direction: column;
  align-items: flex-start;
  @include md {
    flex-direction: row;
    align-items: unset;
  }
}