@import 'themes/colors.scss';
@import 'themes/breakpoints.scss';
@import 'themes/settings.scss';

.profile-page {
  &__title {
    font-weight: 500;
    font-size: pxToRem(28px);
    line-height: pxToRem(34px);
    color: $monochrome_000000;
    margin-bottom: 32px;
    display: flex;
  }
  &__caption {
    font-weight: 400;
    font-size: pxToRem(14px);
    line-height: pxToRem(18px);
    color: $monochrome_81899a;
    display: flex;
    margin-bottom: 4px;
  }
  &__value {
    font-weight: 500;
    font-size: pxToRem(16px);
    line-height: pxToRem(20px);
    color: $monochrome_000000;
    overflow-wrap: break-word;
    &_big {
      font-size: 24px;
      line-height: 30px;      
    }
  }
  &__items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__item {
    width: 100%;
    &_wrapper {
      display: none;
    }
    @include md {
      width: 24%;
      &_wrapper {
        width: 14%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &_widthunset {
        width: unset;
      }
      &_width {
        flex: 1;
      }
    }
    margin-bottom: 32px;
  }

  &__wrapper {
    height: 32px;
    border-right: 1px solid #D7D9DA;
  }
}

.margin-top {
  margin-top: 16px;
}

.help-icon {
  display: flex;
  //align-items: flex-end;
  //gap: 16px;
}

.help-icon > * + * { 
  margin-left: 16px;
}

.question-mark {
  width: 20px;
}