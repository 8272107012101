@import 'themes/breakpoints.scss';

.ham {
  flex: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &__rotate {
    &.active {
      transform: rotate(45deg);
    }
  }

  &__main-menu {
    &_top {
      stroke-dasharray: 40 139;
    }
    &_bottom {
      stroke-dasharray: 40 180;
    }    
  }

  &__main-menu {
    &_top {
      stroke-dasharray: 40 139;
    }
    &_bottom {
      stroke-dasharray: 40 180;
    }    
  }
  &__line {
    fill:none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke:#000;
    stroke-width:5.5;
    stroke-linecap:round;

  }


}  

.white.ham {
    position: relative;
    right: -12px;
    .ham__line {
      stroke:#fff;
    }
}

.active {
  & .ham__main-menu {
    &_top {
      stroke-dashoffset: -98px;
    }
    &_bottom {
      stroke-dashoffset: -138px;
    }    
  }
}

.SurveyStepsIcon {
  height: 71px;
  @include md {
    height: 87px;
  }
}

.LockerIcon {
  flex: none;
}

.loading {
  animation: loading 3s linear infinite;
  @keyframes loading {
   0% { 
     transform: rotate(0); 
   }
   100% { 
     transform: rotate(360deg);
   }
  }
}