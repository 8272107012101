@import 'themes/colors.scss';
@import 'themes/breakpoints.scss';

.header {
  position: relative;
  margin: 0 -16px 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: $monochrome_ffffff;
  align-items: center;
  padding: 12px 0;
  box-shadow: 0px 4px 25px rgba(92, 151, 255, 0.1);
  @include md {
    margin: 0;
    width: 100%;
    background-color: transparent;
    padding: 0 0 31px;
    justify-content: space-between;
    align-items: center;
    box-shadow: none;
  }
}

.sticky {
    position: sticky;
    top: -1px;
    z-index: 5;
    @include md {
      position: relative;
      top: unset;
    }
}

.right-part-container {
  display: none;
  @include md {
    display: flex;
  }
  align-items: center;
}