@import 'themes/colors.scss';

.white-paper {
  background: $monochrome_ffffff;
  border: 1px solid $monochrome_f4f7fc;
  border-radius: 8px;
  padding: 16px 16px 24px 16px;
  position: relative;
  &__fresh {
    background-color: $green_f3fff6;
    &::before {
      content: ' ';
      position: absolute;
      width: 8px;
      height: 8px;
      top: 8px;
      left: 8px;
      border-radius: 50%;
      background-color: $green_01be6f;
      border: 1px solid $green_0fad81;
    }
  }
}