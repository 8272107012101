@import 'reset.scss';
@import 'fonts.scss';
@import 'colors.scss';
@import 'breakpoints.scss';
@import 'settings.scss';

html {
  min-height: 100%;
  font-size: $fs-base;
}

body {
  background: $gradient_body;
  min-height: 100vh;
  font-family: 'Inter'!important;
  overflow-y: scroll;
}

#root {
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  padding-bottom: $footer_height_mobile;
  @include md {
    padding-bottom: $footer_height_desktop;    
  }
}

#modal {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  &.visible {
    display: block;
  }
}

.modal-inner {
  width: 100vw;
  height: 100vh;
  display: grid;
}

.login-form {
  .MuiInputLabel-asterisk {
    display: none;
  }
}

.flex {
  display: flex;
  width: 100%;
  position: relative;
}

.flex-row {
  flex-direction: row;
}

.gap16 {
  //gap: 16px;
}

.gap16 > * + * { 
  
  @include md {
    margin-left: 16px;
  }
}

.align-items-center {
  align-items: center;
}

.line-vertical {
  @include md {
  display: flex;
  align-items: center;
  position: relative;
  &::before, &::after {
    content: ' ';
    top: 0;
    bottom: 0;
    left: 17px;
    width: 1px;
    position: absolute;
    border: 1px dashed $blue_adcdf9;
  } 
  &::before {
    bottom: 50%;
  }
  &::after {
    top: 50%;
  }


  &.active {
    &::before {
      border: 1px solid $violet_8b74d8!important;
    }
  }  

  &.finished {
    &::after {
      border: 1px solid $violet_8b74d8!important;
    }
  }  

  &.no-top-border {
    &::before {
      top: 50%;
    }
  }
  &.no-bottom-border {
    &::after {
      bottom: 50%;
    }
  }  
  }
}

.line-horizontal {
  display: flex;
  position: relative;
  &::before, &::after {
    content: ' ';
    top: 17px;
    left: 0;
    right: 0;
    height: 1px;
    position: absolute;
    border: 1px dashed $blue_adcdf9;
  } 
  &::before {
    right: 50%;
  }
  &::after {
    left: 50%;
  } 

  &.finished {
    &::after, &::before {
      border: 1px solid $blue_4baff3!important;
    }
    &:first-child{
      &::before {
      border: 0!important;
      }
    }
  }  

  &.active {
    &::before, &::after {
      border: 1px solid $violet_8b74d8!important;
      
    }
      &:first-child{
        &::before {
        border: 0;
        }
      }
  } 
  
  &.no-top-border {
    &::before {
      top: 50%;
    }
  }
  &.no-bottom-border {
    &::after {
      bottom: 50%;
    }
  }  
}

.no-top-margin {
  margin-top: 0!important;
}

@keyframes rotation {
	0% {
		transform:rotate(0deg);
	}
	100% {
		transform:rotate(360deg);
	}
}

.form-submit-error {
  color: $red_eb5757;
  font-weight: 400;
  font-size: pxToRem(11px);
  line-height: pxToRem(13px);
  width: inherit;
  &__login-form {
    margin-top: 10px;
    width: inherit;
  }
}
.form-success-response {
    font-size: pxToRem(20px);
    line-height: pxToRem(24px);
    color: $green_01be6f;    
}
.width-unset {
  display: flex;
  flex-direction: column;
  //gap: 16px;
  margin-top: 32px;
  & button {
    min-width: 150px;
    width: unset!important;
  }
  @include md {
    flex-direction: row-reverse;
    justify-content: space-between;
    //gap: 0;
    margin-top: 24px;
  }

  & button:nth-child(2n) {
    margin-top: 16px;
    @include md {
      margin-top: 0;
    }
  }

}
.width-unset > * + * { 

}
.react-modal-sheet-container {
  border-top-right-radius: 20px!important;
  border-top-left-radius: 20px!important;
}

.MuiFormLabel-asterisk {
  color: #EB5757;
}

.MuiFormLabel-asterisk.Mui-error {
  color: #FF002E;
}

.fade {
  transition: all 0.5s ease-in-out;
  opacity: 0;

  &--entering {
    opacity: 1;
  }

  &--entered {
    opacity: 1;
  }

  &--exiting {
    opacity: 0;
  }

  &--exited {
    opacity: 0;
  }
}

#react-portal-wrapper {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 99999999;
  padding: 40px 20px 20px;
}

.button {
  color: #ffffff!important;
  border-radius: 90px;
  background-color: #8B74D8;
  text-decoration: none;
  text-transform: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 14px 24px;

  &:hover {
    background-color: #625FBF;
  }
}

.button-bordered {
  color: #8B74D8;
  border: 2px solid #8B74D8;
  border-radius: 90px;
  background-color: transparent;
  text-decoration: none;
  text-transform: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 14px 32px;
  display: inline-block;

  &:hover {
    border: 2px solid #625FBF;
    color: #625FBF;
  }
}

.MuiAutocomplete-listbox {
  font-family: 'Inter'!important;
}

#portal {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
#portal:empty {
  display: none;
}
/* 
dadata overwrite styles
*/
.react-dadata {
  &__container {
    width: 100%;
  }
}
.dadata-note-none {
  & .react-dadata__suggestion-note {
    display: none;
  }
}
.react-dadata--highlighted {
  color: #625FBF;
}
.smart-captcha {
  height: 0!important;
}

.mobile {
  display: block;  
  @include md {
    display: none;
  }
}
.desktop {
  display: none;
  @include md {
    display: block;

  }
}