@import 'themes/colors.scss';
@import 'themes/settings.scss';

.step-number-container {
  width: 100%;
  display: flex;
  align-items: center;
  //gap: 16px;
  text-decoration: none!important;
}
.step-number-container > * + * { 
  margin-left: 16px;
}
.step-number {
  border: 1px solid $blue_adcdf9;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  font-weight: 500;
  font-size: pxToRem(14px);
  line-height: pxToRem(18px);
  color: $blue_adcdf9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  background-color: $monochrome_ffffff;
  &_active {
    border: $violet_8b74d8;
    background-color: $violet_8b74d8;
    color: $monochrome_ffffff;
  }  
  &_finished {
    border: $blue_4baff3;
    background-color: $blue_4baff3;
  }
  &_disabled {
    border: $blue_adcdf9;
    background-color: $blue_adcdf9;
  }
  &__name {
    font-size: pxToRem(20px);
    line-height: pxToRem(24px);
    color: $monochrome_000000;
    &_active {
      color: $violet_8b74d8;
      text-decoration: none;
    }
    &_finished {
      color: $blue_4baff3;
      text-decoration: none;
    }
    &_disabled {
      color: $blue_adcdf9;
      text-decoration: none;
    }
  }
}