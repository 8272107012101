@import 'themes/colors.scss';
@import 'themes/breakpoints.scss';
@import 'themes/settings.scss';

.modal {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999;
  padding: 66px 20px 20px;
  @include md {
    padding: 20px;
  }
  opacity: 0;
  pointer-events: none;
  transform: scale(0.4);
  width: 100vw;
  height: 100vh;
}


.modal-enter-done {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1);
}

.modal-exit {
  opacity: 0;
  transform: scale(0.4);
}

.modal-content {
  background-color: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: auto;
}

.close-btn {
  background: unset;
  outline: 0;
  border: 0;
  position: absolute;

  right: 3px;
  top: 8px;      

  @include md {
    right: 20px;
    top: 20px;    
  }

  cursor: pointer;
  z-index: 10;
}