@import 'themes/colors.scss';
@import 'themes/settings.scss';

.step-number-container {
  width: 100%;
  display: flex;
  align-items: center;
  //gap: 16px;
}

.step-number-container > * + * { 
  margin-left: 16px;
}

.step-number {
  border: 1px solid $violet_8b74d8;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  font-weight: 500;
  font-size: pxToRem(14px);
  line-height: pxToRem(18px);
  color: $violet_8b74d8;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  background-color: $monochrome_ffffff;

  
  &__name {
    font-size: pxToRem(24px);
    line-height: pxToRem(30px);
    color: $monochrome_ffffff;
    text-decoration: none;
  }
}