@mixin full_label {
  top: 10px!important;
  font-size: 11px!important;
  line-height: 13.5px!important;
}

.root {
  position: relative;
  width: 100%;
  &__label {
    width: calc(100% - 19px);
    
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 18px;
    font-family: Inter;
    color: #81899a;

    position: absolute;
    top: 20px;
    left: 16px;
    
    transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    &_full {
      @include full_label;
    }
  }

  textarea {
    width: 100% !important;
    min-height: 52px !important;
    padding: 25px 16px 0;

    border: 1px solid #d7d9da;
    border-radius: 4px;
    outline: none;

    &:focus + label {
      @include full_label;
    }
  }
}
