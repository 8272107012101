$button-width: 132px;

.button {
  padding: 6px 16px;
  background: #f8f8f8 !important;
  border-radius: 90px !important;
  width: $button-width;
  color: #81899a !important;

  &__icon {
    margin-right: 8px;
  }
}

.buttonWrapper {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 16px;

  &:before,
  &:after {
    content: "";
    background-color: #d7d9da;

    width: calc(50% - $button-width / 2);
    height: 2px;

    position: absolute;
    transform: translateY(-50%);
    top: 50%;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}